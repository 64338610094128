import { Button, Input, Popover, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { BookingFilterOptions } from '../../../requests'
import { DatacIcon } from '../../common'

interface Props {
  isSearching: boolean
  showFilters: boolean
  onSearchChange: (value: string) => void
  onFilterChange: (filter: BookingFilterOptions) => void
  initialSearchValue: string
  initialFilterValue: BookingFilterOptions
}

export const BookingFilters: React.VFC<Props> = ({
  isSearching,
  showFilters,
  onSearchChange,
  onFilterChange,
  initialSearchValue,
  initialFilterValue
}: Props) => {
  const intlBookings = useScopedIntl('calendar.bookings')
  const [searchValue, setSearchValue] = useState(initialSearchValue)
  const [filterValue, setFilterValue] = useState(initialFilterValue)
  const [isSearchClicked, setIsSearchClicked] = useState(false)
  const [isFiltersOpened, setIsFiltersOpened] = useState(false)

  const isSearchExpanded = isSearchClicked || initialSearchValue

  const onSearch = useCallback(
    debounce((value: string) => {
      onSearchChange(value)
    }, 1000),
    []
  )

  const cleanSearch = () => {
    setIsSearchClicked(false)
    onSearchChange(undefined)
    setSearchValue(undefined)
  }

  const onFilter = (e: RadioChangeEvent) => {
    onFilterChange(e.target.value)
    setFilterValue(e.target.value)
  }

  const filterPopoverContent = (
    <div className="bookings-filters__filter-dropdown__content">
      <div className="bookings-filters__filter-dropdown__description">{intlBookings('filters.description')}</div>
      <Radio.Group onChange={onFilter} value={filterValue}>
        <Radio value={BookingFilterOptions.UPCOMING}>{intlBookings('filter.upcoming')}</Radio>
        <Radio value={BookingFilterOptions.ALL}>{intlBookings('filter.all')}</Radio>
        <Radio value={BookingFilterOptions.PAST}>{intlBookings('filter.past')}</Radio>
      </Radio.Group>
    </div>
  )

  return (
    <div className="bookings-filters">
      {showFilters && (
        <div className="bookings-filters__filter-dropdown">
          <Popover
            content={filterPopoverContent}
            trigger="click"
            placement="bottomRight"
            open={isFiltersOpened}
            onOpenChange={setIsFiltersOpened}
          >
            <Button type="default">
              <DatacIcon name="filter" raw />
              {intlBookings('filters.label')}
            </Button>
          </Popover>
        </div>
      )}
      <div
        className={classNames({
          'bookings-filters__search': true,
          'bookings-filters__search--expand': isSearchExpanded
        })}
      >
        {!isSearchExpanded && (
          <Button onClick={() => setIsSearchClicked(true)} type="default">
            <DatacIcon name="search" raw />
          </Button>
        )}
        {isSearchExpanded && (
          <>
            <Input.Search
              size="large"
              onChange={e => {
                onSearch(e.target.value)
                setSearchValue(e.target.value)
              }}
              loading={isSearching}
              value={searchValue}
              placeholder={intlBookings('search.placeholder')}
            />
            <Button onClick={cleanSearch} type="default" className="bookings-filters__search__clean">
              <DatacIcon name="x" raw />
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
