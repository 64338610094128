import dayjs, { Dayjs } from 'dayjs'
import { View } from 'react-big-calendar'
import { create } from 'zustand'

import { Appointment } from '../../requests'
import { DatacEvent } from './CalendarContent'

interface RequestDates {
  startDate: Dayjs
  endDate: Dayjs
}

interface CalendarStoreState {
  currentView: View
  currentDate: Date
  setCurrentView: (currentView: View, refetchEvents?: boolean) => void
  setCurrentDate: (currentDate: Date, forceDay?: boolean) => void
  goToNext: (forceDay?: boolean) => void
  goToPrevious: (forceDay?: boolean) => void
  requestDates: RequestDates
  forcedEventDetailsId: number
  clearForcedEventDetailsId: () => void
  forceEventDetails: (event: DatacEvent) => void
  appointmentToEdit: Appointment | null
  setAppointmentToEdit: (event: Appointment | null) => void
  showWeekends: boolean
  setShowWeekends: (showWeekends: boolean) => void
  startsOnSunday: boolean
  setStartsOnSunday: (startsOnSunday: boolean) => void
  showEarlyHours: boolean
  setShowEarlyHours: (showEarlyHours: boolean) => void
  userTimezone: string
  setUserTimezone: (userTimezone: string, currentDate?: Date) => void
}

const getRequestedDates = (currentDate: Dayjs, currentView: View, timezone: string): RequestDates => {
  const dateInUserZone = dayjs.tz(currentDate, timezone)
  switch (currentView) {
    case 'week':
      return { startDate: dateInUserZone.clone().startOf('week'), endDate: dateInUserZone.clone().endOf('week') }
    case 'day':
      return { startDate: dateInUserZone.clone().startOf('day'), endDate: dateInUserZone.clone().endOf('day') }
    case 'agenda':
      return {
        startDate: dateInUserZone.startOf('day'),
        endDate: dateInUserZone.clone().add(31, 'days').endOf('day')
      }
    default:
      return { startDate: dateInUserZone.clone().startOf('month'), endDate: dateInUserZone.clone().endOf('month') }
  }
}

export const useCalendarStore = create<CalendarStoreState>()(set => ({
  currentView: 'week',
  setCurrentView: (currentView: View, refetchEvents = true) =>
    set(({ currentDate, userTimezone }: CalendarStoreState) =>
      refetchEvents
        ? { currentView, requestDates: getRequestedDates(dayjs(currentDate), currentView, userTimezone) }
        : { currentView }
    ),
  currentDate: new Date(),
  setCurrentDate: (currentDate: Date, forceDay = false) =>
    set(({ currentView, userTimezone }: CalendarStoreState) => {
      return {
        currentDate,
        requestDates: getRequestedDates(dayjs(currentDate), forceDay ? 'day' : currentView, userTimezone)
      }
    }),
  goToNext: (forceDay = false) =>
    set(({ currentDate, currentView, userTimezone }: CalendarStoreState) => {
      let nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1)
      if (!forceDay && currentView === 'month')
        nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
      if (!forceDay && currentView === 'week')
        nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7)

      return {
        currentDate: nextDate,
        requestDates: getRequestedDates(dayjs(nextDate), forceDay ? 'day' : currentView, userTimezone)
      }
    }),
  goToPrevious: (forceDay = false) =>
    set(({ currentView, currentDate, userTimezone }: CalendarStoreState) => {
      let previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1)
      if (!forceDay && currentView === 'month')
        previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate())
      if (!forceDay && currentView === 'week')
        previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7)

      return {
        currentDate: previousDate,
        requestDates: getRequestedDates(dayjs(previousDate), forceDay ? 'day' : currentView, userTimezone)
      }
    }),
  requestDates: getRequestedDates(dayjs(), 'week', dayjs.tz.guess()),
  forcedEventDetailsId: null as number,
  clearForcedEventDetailsId: () => set({ forcedEventDetailsId: null }),
  forceEventDetails: (event: DatacEvent) => set({ forcedEventDetailsId: event.id }),
  appointmentToEdit: null,
  setAppointmentToEdit: (event: Appointment | null) => set({ appointmentToEdit: event }),
  showWeekends: false,
  setShowWeekends: (showWeekends: boolean) => set({ showWeekends }),
  startsOnSunday: false,
  setStartsOnSunday: (startsOnSunday: boolean) => set({ startsOnSunday }),
  showEarlyHours: false,
  setShowEarlyHours: (collapseEarlyHours: boolean) => set({ showEarlyHours: collapseEarlyHours }),
  userTimezone: dayjs.tz.guess(),
  setUserTimezone: (userTimezone: string, currentDate?: Date) =>
    set({
      userTimezone,
      currentView: 'week',
      requestDates: getRequestedDates(currentDate ? dayjs(currentDate) : dayjs(), 'week', userTimezone)
    })
}))
