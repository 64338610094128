import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { BookingsContent } from '../../components/calendar/BookingsContent'
import { AccountType } from '../../requests'

const Bookings = () => {
  return (
    <>
      <SessionTimeoutModal accountType={AccountType.User} />
      <AuthRoute>
        <BasicLayout>
          <BookingsContent />
        </BasicLayout>
      </AuthRoute>
    </>
  )
}

export default Bookings
export const Head = () => <title>Datacapt - Bookings</title>
