import { Button } from 'antd'
import classNames from 'classnames'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AclFeature, Booking } from '../../../requests'
import { routes } from '../../../routes'
import { UserContext } from '../../auth'
import { DatacAvatar, DatacDropdownMenu, DatacDropdownMenuOptions, DatacIcon } from '../../common'
import { AddTestModal } from './AddTestModal'

enum BookingActionType {
  Access = 'Access'
}

interface Props {
  booking: Booking
}

export const BookingRecord: React.VFC<Props> = ({ booking }: Props) => {
  const intlBookings = useScopedIntl('calendar.bookings')
  const [isAddTestModalOpened, setIsAddTestModalOpened] = useState(false)
  const { user } = useContext(UserContext)

  const menuOptions: DatacDropdownMenuOptions<BookingActionType> = [
    {
      type: BookingActionType.Access,
      label: 'studies.fulfillment.subject_profile',
      icon: 'user'
    }
  ]

  const handleMenuClick = (item: string) => {
    switch (item) {
      case BookingActionType.Access:
        navigate(routes.subjectRepositoryProfile(booking.subjectId))
        break
      default:
        break
    }
  }

  const areTestsAvailable = user.isSideBySideEnabled && user.canAccess(AclFeature.SideBySide)

  return (
    <div className={classNames({ 'bookings-record': true, 'bookings-record--past': booking.past })}>
      <div className="bookings-record__hours">
        {`${booking.startTime} - ${booking.endTime}`}
        <small>
          <DatacIcon name="clock" raw /> {`${booking.duration} min`}
        </small>
      </div>
      <div className="bookings-record__subject">
        <div className="bookings-record__subject__thumbnail">
          <DatacAvatar
            photoThumbnail={booking.photoThumbnail}
            photo={booking.photo}
            firstName={booking.firstName}
            lastName={booking.lastName}
          />
        </div>
        <div className="bookings-record__subject__details">
          <div
            className="bookings-record__subject__details__link"
            onClick={() => navigate(routes.subjectRepositoryProfile(booking.subjectId))}
          >
            <strong>
              {booking.firstName} {booking.lastName}
            </strong>
          </div>
          <small>{booking.subjectId}</small>
        </div>
      </div>
      <div className="bookings-record__appointment">
        <div className="bookings-record__appointment__details">
          <strong>{booking.title}</strong>
          {booking.study.reference && (
            <small>
              <DatacIcon name="file" raw />
              {booking.study.name} {booking.study.reference}
            </small>
          )}
          {booking.tests?.slice(0, 2).map(test => (
            <small
              key={test.projectId}
              className="bookings-record__appointment__link"
              onClick={() => navigate(routes.sideBySideProjectTest(test.projectId, test.testId, null, null, true))}
            >
              <DatacIcon name="file" raw />
              {test.projectName}
            </small>
          ))}
        </div>
      </div>
      <div className="bookings-record__actions">
        {areTestsAvailable && (
          <Button type="default" onClick={() => setIsAddTestModalOpened(true)}>
            {intlBookings('link_test')}
          </Button>
        )}
        <DatacDropdownMenu options={menuOptions} onClick={item => handleMenuClick(item)} />
      </div>
      {areTestsAvailable && (
        <AddTestModal
          isOpened={isAddTestModalOpened}
          onClose={() => setIsAddTestModalOpened(false)}
          booking={booking}
        />
      )}
    </div>
  )
}
