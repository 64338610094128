import React from 'react'

import { Project } from '../../../../requests'
import { scopedIntlType } from '../../../common'

interface GetColumnsOptions {
  columnNames: {
    name: string
    brand: string
    category: string
  }
  actionCell: (project: Project) => JSX.Element
}

export const getListColumns = ({ columnNames, actionCell }: GetColumnsOptions) => {
  return [
    {
      title: columnNames.name,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      render: (name: string) => <strong>{name}</strong>
    },
    {
      title: columnNames.brand,
      dataIndex: 'brand',
      key: 'brand',
      sorter: true,
      ellipsis: true,
      width: '19rem'
    },
    {
      title: columnNames.category,
      dataIndex: 'category',
      key: 'category',
      ellipsis: true,
      sorter: true,
      width: '15rem'
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (project: Project) => actionCell(project),
      width: '15rem'
    }
  ]
}

export const searchAndFilterOptions = (intl: scopedIntlType) => {
  const intlCommon = (id: string) => intl(`common.${id}`)
  return {
    searchInputPlaceholder: intlCommon('search')
  }
}
